<template>
  <base-form-item :field="field">
    <template v-if="value && value !== '0000-00-00 00:00:00'">
      <span>{{ formatedDataTime }}</span>
    </template>
    <template v-else>
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </template>
  </base-form-item>
</template>

<script>
import moment from 'moment';
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

export default {
  name: 'index',
  mixins: [abstractForm],
  components: {BaseFormItem},
  computed: {
    formatedDataTime() {
      return moment(this.value).format(this.format);
    },
    format() {
      return this.meta.format ? this.meta.format : 'YYYY-MM-DD HH:mm:ss';
    },
  },
};
</script>

<style scoped>

</style>
